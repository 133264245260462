export const siteName = "Erdaş Sanal Market";
export const siteNameUpper = "ERDAŞ SANAL MARKET";

export const siteMailList = [
  {
    mail: "bilgi@erdasmarket.com",
    subject: "Bize Mail Bırakın",
  },
];

export const sitePhoneList = [
  { phone: "444 46 59", info: "" },


];

export const eklenenMiktarKatsayisi = 0.1;
