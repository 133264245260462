<template>
  <div id="footer">
    <div class="row">
      <div class="col-md-1"></div>
      <div class="col-md-10">
        <div class="row">
          <div class="col-lg-8">
            <div class="row">
              <div class="col-lg-4">
                <div class="footer-baslik">KATEGORİLER</div>
                <div class="footer-altbaslik">
                  <FooterGruplar />
                </div>
              </div>
              <div class="col-lg-2">
                <div class="footer-baslik">HESABIM</div>
                <div class="footer-altbaslik">
                  <router-link to="/profil/siparislerim"
                    ><span>Siparişlerim</span></router-link
                  >
                </div>
                <div class="footer-altbaslik">
                  <router-link to="/profil/adreslerim"
                    ><span>Adreslerim</span></router-link
                  >
                </div>
                <div class="footer-altbaslik">
                  <router-link to="/profilim"
                    ><span>Profil Bilgilerim</span></router-link
                  >
                </div>
                <div class="footer-altbaslik">
                  <router-link to="/profil/geri-bildirim"
                    ><span>Geri Bildirim</span></router-link
                  >
                </div>
              </div>
              <div class="col-lg-2">
                <div class="footer-baslik">KURUMSAL</div>
                <div class="footer-altbaslik">
                  <router-link to="/hakkimizda">
                 </router-link> 

                </div>

             

              </div>
              <!--
              <div class="col-lg-2">
                <div class="footer-baslik">TESLİMAT</div>
                <div class="footer-altbaslik">
                  <router-link to="/cayma-hakki"
                    ><span>Cayma Hakkı</span></router-link
                  >
                </div>
                <div class="footer-altbaslik">
                  <router-link to="/cayma-hakki"
                    ><span>Ürün Değişimi</span></router-link
                  >
                </div>
              </div>
            -->
              <div class="col-lg-4">
                <div class="footer-baslik">YARDIM</div>
                <div class="footer-altbaslik">
                  <router-link to="/gizlilik"
                    ><span>Gizlilik Sözleşmesi</span></router-link
                  >
                </div>
                <div class="footer-altbaslik">

                  <router-link to="/kvkk"
                    ><span>Kişisel Verilerin Korunması</span></router-link >



                </div>
                <div class="footer-altbaslik">
                  <router-link to="/uyeliksozlesme"
                    ><span>Üyelik Sözleşmesi</span></router-link
                  >
                </div>
                <div class="footer-altbaslik">
                  <router-link to="/kvkk"
                    ><span>Kişisel Verilere İlişkin Beyan</span></router-link
                  >
                </div>

                <div class="footer-altbaslik">
                  <router-link to="/mesafesozlesme"
                    ><span>Mesafeli Satış Sözleşmesi</span></router-link
                  >
                </div>

                <div class="footer-altbaslik">
                  <router-link to="/kaydol"
                    ><span>Yeni Üyelik</span></router-link
                  >
                </div>
                <div class="footer-altbaslik">
                  <router-link to="/profil/geri-bildirim"
                    ><span>Geri Bildirim</span></router-link
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="footer-baslik">İLETİŞİM</div>

            <div v-for="i in phoneList2" :key="i.phone" class="footer-altbaslik">
              <span><i class="fas fa-phone-alt"></i></span>
              <span
                ><a :href="telPhone(i.phone)"
                  >{{ i.phone }} {{ i.info }}</a
                ></span
              >
            </div>
            <div v-for="i in siteMailList2" class="footer-altbaslik" :key="i.mail">
              <span><i class="fas fa-envelope"></i></span>
              <span
                ><a :href="getMailtoLink(i.mail, i.subject)">{{
                  i.mail
                }}</a></span
              >
            </div>
            <div class="footer-ios-play">
              <span
                ><a
                  href="https://apps.apple.com/tr/app/g%C3%BCnkay-sanal-market/id1530725843"
                  target="_blank"
                  ><img src="@/assets/svg/icon-ios.svg" alt="ios" /></a
              ></span>
              <span
                ><a
                  href="https://play.google.com/store/apps/details?id=com.zdc.android.gunkay&hl=tr"
                  ><img src="@/assets/svg/icon-play.svg" alt="play" /></a
              ></span>
            </div>
            <div class="footer-social">
              <SocialMediaIcons />
            </div>

            <!--
            <div class="footer-etbis2">
                <div id="ETBIS">
                  <div id="01a73e3c64bc48efbc31dd122e8afe61">
                    <a href="https://www.eticaret.gov.tr/siteprofil/7560338865822374/xxx" target="_blank">
                      </a>
                      </div>
                      </div>
                <img style='width:125px; height:150px' src="@/assets/img/etbis.jpg" alt="logo" />
              </div>
            -->

          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div class="footer-bottom">
              <router-link to="/">
                <img src="@/assets/img/logo-footer2.png" alt="logo" />
              </router-link>
              <div class="footer-son">
                <span>2023 ® {{ siteName2 }} |</span> Tüm hakları saklıdır.
              </div>
              <div class="footer-etbis">
                <img src="@/assets/img/visa-master.png" alt="logo" />
              </div>

            </div>


          </div>
        </div>



        <div class="row">
          <div class="col-lg-12">
            <div class="footer-son mb-2">
              <span
                >Powered By
                <a href="https://market.zdc.com.tr/" target="_blank"
                  >ZDC Bilişim</a
                ></span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-1"></div>
    </div>
  </div>
</template>

<script>
import SocialMediaIcons from "@/components/SocialMediaIcons";
import FooterGruplar from "@/components/FooterGruplar";
import { siteName, sitePhoneList, siteMailList } from "../zdcconfig/zdconfig";

export default {
  data() {
    return {
      phoneList2: sitePhoneList,
      siteName2: siteName,
      siteMailList2: siteMailList,
    };
  },
  components: {
    SocialMediaIcons,
    FooterGruplar,
  },
  methods: {
    telPhone(p) {
      return `tel:${p.replace(/\s/g, "")}`;
    },
    getMailtoLink(mail, sub) {
      return `mailto:${mail}?subject=${sub}`;
    },
  },
};
</script>

<style scoped>
@media (max-width: 990px) {
  #footer {
    display: none !important;
  }
}
#footer {
  border-top: 1px solid #d8d8d8;
  padding: 40px 40px 0 40px;
  font-family: "Trebuchet MS";
}
.footer-baslik {
  font-size: 16px;
  color: #E02118;
  font-weight: 800;
  padding-bottom: 25px;
}

.footer-altbaslik {
  font-size: 14px;
  font-weight: 400;
  padding-bottom: 10px;
  color: black;
  transition: 0.5s;
}
.footer-altbaslik span {
  transition: 0.3s;
}
.footer-altbaslik a {
  color: black;
  cursor: pointer;
}
.footer-altbaslik span:hover {
  color: #E02118;
  cursor: pointer;
}
.footer-altbaslik span i {
  border: 2px solid #eeeeee;
  border-radius: 20px;
  padding: 10px;
  transition: 0.3s;
}
.footer-altbaslik span i:hover {
  border-color: #d6d6d6;
}
.footer-altbaslik span:nth-child(2) {
  font-weight: 400;
  padding-left: 10px;
}
i {
  color: #4d4d4d;
}
.footer-social {
  margin-top: 10px;
}
.footer-social i {
  border: 2px solid #eeeeee;
  border-radius: 30px;
  padding: 15px;
  transition: 0.3s;
  margin-right: 10px;
  font-size: 18px;
}
.fa-facebook-f {
  min-width: 50px;
  padding-left: 17px !important;
}
.footer-social span i:hover {
  border: 2px solid #E02118;
  background: #E02118;
  color: white;
  cursor: pointer;
  box-shadow: 0 4px 4px 0 #6b6b6b;
}
.footer-ios-play {
  margin-top: 20px;
}
.footer-ios-play span {
  margin-right: 20px;
}
.footer-ios-play img {
  transition: 0.3s;
}
.footer-ios-play span:hover img {
  transform: translateY(-5px);
  box-shadow: 0 5px 5px 0 rgb(151, 151, 151);
}
.footer-bottom::before {
  content: "";
  display: block;
  margin: 30px 0 30px 0;
  width: 100%;
  padding-top: 2px;
  border-bottom: 1px solid #d8d8d8;
}
.footer-bottom img {
  width: 250px;
  object-fit: contain;
}
.footer-bottom {
  font-size: 14px;
  color: #5e5e5e;
}
.footer-bottom span {
  font-weight: 800;
  padding-left: 20px;
  color: #424242;
}
.footer-bottom div {
  margin-top: 10px;
}
.footer-son {
  margin: 20px 0 5px 0;
  display: flex;
  justify-content: center;
  font-size: 13px;
}
.footer-etbis {
  position: absolute;
  top: 80px;
  right: 20px;
}
.footer-etbis2 {
  margin-top: 0px;
  display: flex;
  justify-content: left;
}

.footer-son a {
  color: #E02118;
}
</style>
