<template>
  <div id="seconNavbarID" class="d-flex flex-column" :style="navbar2Color">
    <div class="d-flex justify-content-between align-items-center noselect">
      <div><AdresSecim /></div>
      <div><EnYakinRandevuTarihi /></div>
      <div id="sepetNavID" v-if="showSepetNavbar"><SepetNavbar /></div>
    </div>
    <div class="d-flex textbuttons">
      <div class="nav2textbutton" @click="openModal()">
        <i class="fas fa-bars"></i>
        <span> Kategoriler </span>
      </div>
      <p class="nav2textbutton" @click="goToKampanyaPage()">Kampanyalar</p>
      <div v-if="showModal">
        <div class="modaloverlay" @click="closeModal"></div>
        <!-- /* -------------------------------------------- modal ------------------------------------------- */ -->
        <div class="modalcontent row d-flex" @mouseleave="onHover = false">
          <div class="d-flex col">
            <div class="modaldata">
              <div style="width: 100%">
                <button
                  @click="closeModal"
                  type="button"
                  class="kapatbuton"
                  aria-label="Close"
                >
                  <span aria-hidden="true" class="black-text">&times;</span>
                </button>
              </div>
              <p
                class="modaltext"
                v-for="i in gruplar"
                @click="gotoGrupPage(i)"
                @mouseover="runMethods(i.id)"
                :key="i.id"
              >
                {{ i.ad }}
              </p>
            </div>
            <div
              :class="{ showaltGrups: onHover, hidealtGrups: !onHover }"
              style="
                padding-top: 30px;
                display: flex;
                flex-wrap: wrap;
                margin-right: 16px;
                justify-items: start;
                align-items: start;
                height: min-content;
                color: black;
              "
            >
              <p
                @click="gotoAltgrupPage(j)"
                v-for="j in altgruplar"
                :key="j.id"
                style="margin-right: 32px"
                class="hoverclick"
              >
                {{ j.ad }}
              </p>
            </div>
          </div>
        </div>
        <!-- /* -------------------------------------------- modal ------------------------------------------- */ -->
      </div>
    </div>
  </div>
</template>
<script>
import EnYakinRandevuTarihi from "@/components/EnYakinRandevuTarihi";
import AdresSecim from "@/components/AdresSecim";
import SepetNavbar from "@/components/SepetNavbar";
import { mapGetters } from "vuex";
import axios from "axios";
export default {
  data() {
    return {
      showSepetNavbar: true,
      showModal: "",
      gruplar: [],
      errors: [],
      onHover: false,
      altgruplar: [
        { id: 1, ad: "jjjjjjjjj" },
        { id: 2, ad: "tttttttt" },
        { id: 3, ad: "aaaaaaaa" },
        { id: 4, ad: "dddddddd" },
      ],
    };
  },
  components: {
    EnYakinRandevuTarihi,
    AdresSecim,
    SepetNavbar,
  },
  computed: {
    ...mapGetters(["navbar2Color", "serverRequest"]),
  },
  methods: {
    runMethods(id) {
      this.onHover = true;
      this.altgruplar = this.gruplar.find((g) => g.id == id)?.altgruplar || [];
    },
    butonKontrolu() {
      // SepetOdemePage, her adresteki 'sil' butonunun yerine 'ekle' butonunun show/hide-i icin kontrol
      var baseUrl = document.baseURI;
      if (baseUrl.includes("/sepet/odeme") || baseUrl.includes("/sepet")) {
        this.showSepetNavbar = false;
      }
    },
    goToKampanyaPage() {
      if (this.$router.currentRoute.path !== "/kampanyalar") {
        this.$router.push("/kampanyalar");
      }
    },
    openModal() {
      if (this.gruplar.length > 0) {
        this.showModal = "show";
      }
    },
    gotoGrupPage(grup) {
      if (
        (this.$router.currentRoute.name === "AltgruplarPage" &&
          this.$router.currentRoute.params.id !== grup.id) ||
        this.$router.currentRoute.name !== "AltgruplarPage"
      ) {
        this.$router.push({
          name: "AltgruplarPage",
          params: { id: grup.id, g_ad: grup.ad.replace(/\//g, "") },
        });
      }
      this.closeModal();
    },
    gotoAltgrupPage(altgrup) {
      var grup = this.gruplar.find((grup) =>
        grup.altgruplar.some((subgrup) => subgrup.id === altgrup.id)
      );
      if (
        (this.$router.currentRoute.name == "UrunlerPage" &&
          this.$router.currentRoute.params.id != altgrup.id) ||
        this.$router.currentRoute.name != "UrunlerPage"
      ) {
        this.$router.push({
          name: "UrunlerPage",
          params: {
            g_id: grup.id,
            id: altgrup.id,
            g_ad: grup.ad.replace(/\//g, ""),
            altg_ad: altgrup.ad.replace(/\//g, ""),
          },
        });
      }
      this.closeModal();
    },
    closeModal() {
      this.showModal = "";
    },
    getUid_Udep() {
      this.ushp = this.decryptCookie("ushp");
      this.udep = this.decryptCookie("udep");
    },
    async fetchGruplar() {
      this.getUid_Udep();
      try {
        const formData = new FormData();
        formData.append("f", "get_group_data");
        formData.append("depo", this.udep);
        const response = await axios.post(this.serverRequest, formData, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
        });
        this.gruplar = response.data[0].gruplar;
        var altgruplar = response.data[1].altgruplar;
        this.gruplar.forEach(
          (grup) =>
            (grup.altgruplar = altgruplar.filter(
              (altgrup) => altgrup.grup == grup.id
            ))
        );
      } catch (error) {
        this.errors.push(error);
      }
    },
  },
  mounted() {
    this.butonKontrolu();
    this.fetchGruplar();
  },
};
</script>
<style scoped>
@media (max-width: 1200px) {
  #seconNavbarID {
    padding-right: 1% !important;
    padding-left: 1% !important;
  }
}
@media (max-width: 1000px) {
  #sepetNavID {
    display: none;
  }
}
#seconNavbarID {
  z-index: 9990;
  border: 1px solid rgb(204, 203, 203, 0.4);
  margin-top: -4px;
  margin-left: -5px;
  color: white;
  font-size: 12px;
  padding: 0px 10px;
  padding-top: 9px;
  min-height: 60px;
  padding-right: 6%;
  padding-left: 6%;
}
.textbuttons {
  display: flex;
  position: relative;
  padding-left: 5px;
  margin-bottom: -8px;
}
@media (max-width: 700px) {
  .textbuttons {
    padding-left: 10px;
    padding-right: 10px;
    justify-content: space-between;
  }
}
.textbuttons .nav2textbutton {
  font-size: 18px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  margin-right: 50px;
  transition: transform 0.5s ease;
}
@media (max-width: 700px) {
  .textbuttons .nav2textbutton {
    margin-right: 0;
    font-size: 16px;
  }
}
.textbuttons .nav2textbutton:hover {
  cursor: pointer;
  transform: scale(1.05);
}
.hoverclick:hover {
  cursor: pointer;
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}
.modaloverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1999;
}
.modalcontent {
  position: absolute;
  top: 40px;
  left: 16px;
  background: white;
  padding: 10px;
  z-index: 2000;
  max-height: fit-content;
  height: fit-content;
  border-radius: 10px;
  width: max-content;
  max-width: 60vw;
}
.modaldata {
  height: min-content;
  background: none;
}
.modalcontent .modaltext {
  text-wrap: nowrap;
  margin-top: -10px;
  padding: 4px;
  border-radius: 4px;
  font-size: 14px;
  color: black;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  transition: transform 0.3s ease, background-color 0.3s ease;
}
.modalcontent .modaltext:hover {
  cursor: pointer;
  transform: scale(1.05);
  /* background-color: #ca2424;
  color: white; */
}
.showaltGrups {
  visibility: visible;
  width: 50vw;
  max-width: 40vw;
}
@media only screen and (max-width: 1268px) {
  .modalcontent {
    padding: 20px;
    margin-left: 8px;
  }
  .showaltGrups {
    max-width: 50vw;
  }
}
@media only screen and (max-width: 768px) {
  .modalcontent {
    padding: 14px;
    margin-left: 8px;
  }
}
.kapatbuton {
  background: none;
  padding: 4px 4px;
  border: none;
  right: 10px;
  margin-left: auto;
  margin-bottom: 10px;
  top: 10px;
  cursor: pointer;
}
.hidealtGrups {
  visibility: hidden;
  width: 0px;
  height: 0px;
}
</style>
